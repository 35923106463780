import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import FissureEmbed from "./FissureEmbed";

function App() {
  return (
    <div>
      <style>
        {`
          body {
            background-color: #2B2D31;
          }
        `}
      </style>
      <FissureEmbed fissureType="Normal" />
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
