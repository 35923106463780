import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Countdown from "react-countdown";
import { FaCog } from "react-icons/fa";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Set the app root element for accessibility

const FissureEmbed = () => {
  const [fissures, setFissures] = useState([]);
  const [selectedFissureType, setSelectedFissureType] = useState(
    localStorage.getItem("selectedFissureType") || ""
  );
  const [selectedTier, setSelectedTier] = useState(
    parseInt(localStorage.getItem("selectedTier")) || 5
  );
  const [prevFissures, setPrevFissures] = useState([]);
  const [showEraConfigModal, setShowEraConfigModal] = useState(false);
  const [showMissionConfigModal, setShowMissionConfigModal] = useState(false);
  const [eraFilters, setEraFilters] = useState(
    JSON.parse(localStorage.getItem("eraFilters")) || []
  );
  const [missionFilters, setMissionFilters] = useState(
    JSON.parse(localStorage.getItem("missionFilters")) || []
  );
  const [tempEraFilters, setTempEraFilters] = useState([]);
  const [tempMissionFilters, setTempMissionFilters] = useState([]);

  useEffect(() => {
    const fetchFissures = async () => {
      try {
        const response = await axios.get("https://relics.run/fissures");
        const sortedFissures = response.data.sort((a, b) =>
          a.fissure_type.localeCompare(b.fissure_type)
        );

        // Remove the dynamic "time_left" element before comparing
        const filteredFissures = sortedFissures.map(
          ({ time_left, ...rest }) => rest
        );
        const filteredPrevFissures = prevFissures.map(
          ({ time_left, ...rest }) => rest
        );

        if (
          JSON.stringify(filteredFissures) !==
          JSON.stringify(filteredPrevFissures)
        ) {
          setFissures(sortedFissures);
          setPrevFissures(sortedFissures);
          if (selectedFissureType === "") {
            setSelectedFissureType(sortedFissures[0].fissure_type);
          }
        }
      } catch (error) {
        console.error("Error fetching fissures:", error);
      }
    };

    fetchFissures();

    const interval = setInterval(fetchFissures, 10000);
    return () => clearInterval(interval);
  }, [selectedFissureType, prevFissures]);

  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <span className="fissure-embed-expiry">
        <span className="fissure-embed-expiry-text">
          {hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`}
        </span>
        <span className="fissure-embed-expiry-hover">
          {hours > 0
            ? `${hours} hours, ${minutes} minutes, ${seconds} seconds`
            : `${minutes} minutes, ${seconds} seconds`}
        </span>
      </span>
    );
  };

  const getEraIcon = (era) => {
    const iconUrl = `https://relics.run/static/images/${era}.webp`;
    return iconUrl;
  };

  const handleFissureTypeChange = (e) => {
    const fissureType = e.target.value;
    setSelectedFissureType(fissureType);
    localStorage.setItem("selectedFissureType", fissureType);
  };

  const handleTierChange = (e) => {
    const tier = Number(e.target.value);
    setSelectedTier(tier);
    localStorage.setItem("selectedTier", tier.toString());
  };

  const handleTempEraFilterChange = (era) => {
    if (tempEraFilters.includes(era)) {
      setTempEraFilters(tempEraFilters.filter((e) => e !== era));
    } else {
      setTempEraFilters([...tempEraFilters, era]);
    }
  };

  const handleTempMissionFilterChange = (mission) => {
    if (tempMissionFilters.includes(mission)) {
      setTempMissionFilters(tempMissionFilters.filter((m) => m !== mission));
    } else {
      setTempMissionFilters([...tempMissionFilters, mission]);
    }
  };

  const saveEraFilters = () => {
    setEraFilters(tempEraFilters);
    localStorage.setItem("eraFilters", JSON.stringify(tempEraFilters));
    toggleEraConfigModal();
  };

  const saveMissionFilters = () => {
    setMissionFilters(tempMissionFilters);
    localStorage.setItem("missionFilters", JSON.stringify(tempMissionFilters));
    toggleMissionConfigModal();
  };

  const formatTime = (time) => {
    return moment.tz(time, "UTC").local().format("h:mm A");
  };

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const getTierOptions = () => {
    const tierOptions = [
      { value: 1, label: "Capture and Exterminate Fissures" },
      { value: 2, label: "+ Rescue and Fast Sabotage" },
      { value: 3, label: "+ Excavation and Disruption" },
      { value: 4, label: "+ Slow Sabotage/Spy/Hive" },
      { value: 5, label: "All Fissures" },
    ];

    return tierOptions;
  };

  const toggleEraConfigModal = () => {
    setShowEraConfigModal((prevShowEraConfigModal) => !prevShowEraConfigModal);
    setTempEraFilters([...eraFilters]);
  };

  const toggleMissionConfigModal = () => {
    setShowMissionConfigModal(
      (prevShowMissionConfigModal) => !prevShowMissionConfigModal
    );
    setTempMissionFilters([...missionFilters]);
  };

  const resetEraFilters = () => {
    setTempEraFilters([]);
  };

  const resetMissionFilters = () => {
    setTempMissionFilters([]);
  };

  const filteredFissures = fissures.filter(
    (fissure) =>
      fissure.fissure_type === selectedFissureType &&
      fissure.tier <= selectedTier &&
      !eraFilters.includes(fissure.era) &&
      !missionFilters.includes(fissure.mission)
  );

  const EraConfigModal = () => (
    <Modal
      isOpen={showEraConfigModal}
      onRequestClose={toggleEraConfigModal}
      contentLabel="Era Config Modal"
      className="fissure-embed-modal"
      overlayClassName="fissure-embed-modal-overlay"
    >
      <div className="fissure-embed-modal-content">
        <h2>Era Filters</h2>
        <div className="fissure-embed-config-options">
          {Array.from(new Set(fissures.map((fissure) => fissure.era))).map(
            (era) => (
              <label key={era}>
                <input
                  type="checkbox"
                  checked={!tempEraFilters.includes(era)}
                  onChange={() => handleTempEraFilterChange(era)}
                />
                <span className="fissure-embed-checkmark"></span>
                {era}
              </label>
            )
          )}
        </div>
      </div>
      <div className="fissure-embed-modal-footer">
        <button className="fissure-embed-modal-reset" onClick={resetEraFilters}>
          Reset
        </button>
        <button className="fissure-embed-modal-save" onClick={saveEraFilters}>
          Save
        </button>
      </div>
    </Modal>
  );

  const MissionConfigModal = () => (
    <Modal
      isOpen={showMissionConfigModal}
      onRequestClose={toggleMissionConfigModal}
      contentLabel="Mission Config Modal"
      className="fissure-embed-modal"
      overlayClassName="fissure-embed-modal-overlay"
    >
      <div className="fissure-embed-modal-content">
        <h2>Mission Filters</h2>
        <div className="fissure-embed-config-options">
          {Array.from(new Set(fissures.map((fissure) => fissure.mission)))
            .sort()
            .map((mission) => (
              <label key={mission}>
                <input
                  type="checkbox"
                  checked={!tempMissionFilters.includes(mission)}
                  onChange={() => handleTempMissionFilterChange(mission)}
                />
                <span className="fissure-embed-checkmark"></span>
                {mission}
              </label>
            ))}
        </div>
      </div>
      <div className="fissure-embed-modal-footer">
        <button
          className="fissure-embed-modal-reset"
          onClick={resetMissionFilters}
        >
          Reset
        </button>
        <button
          className="fissure-embed-modal-save"
          onClick={saveMissionFilters}
        >
          Save
        </button>
      </div>
    </Modal>
  );

  return (
    <div className="fissure-embed">
      <div className="fissure-embed-header">
        <select
          className="fissure-embed-select"
          value={selectedFissureType}
          onChange={handleFissureTypeChange}
        >
          {Array.from(
            new Set(fissures.map((fissure) => fissure.fissure_type))
          ).map((fissureType) => (
            <option key={fissureType} value={fissureType}>
              {fissureType} Fissures
            </option>
          ))}
        </select>
        <div className="fissure-embed-tier-select">
          <span className="fissure-embed-tier-label">Max Tier</span>
          <div className="fissure-embed-tier-select-wrapper">
            <select
              value={selectedTier}
              onChange={handleTierChange}
              className="fissure-embed-tier-select-dropdown"
            >
              {getTierOptions().map((option) => (
                <option key={option.value} value={option.value}>
                  {selectedTier === option.value ? option.value : option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="fissure-embed-table">
        <div className="fissure-embed-row fissure-embed-header-row">
          <div className="fissure-embed-cell fissure-embed-era-header">
            Era
            <button
              className="fissure-embed-config-button"
              onClick={toggleEraConfigModal}
            >
              <FaCog />
            </button>
          </div>
          <div className="fissure-embed-cell fissure-embed-mission-header">
            Mission
            <button
              className="fissure-embed-config-button"
              onClick={toggleMissionConfigModal}
            >
              <FaCog />
            </button>
          </div>
          <div className="fissure-embed-cell fissure-embed-expiry-header">
            Expiry
          </div>
        </div>
        {filteredFissures.map((fissure) => (
          <div key={fissure.node} className="fissure-embed-row">
            <div className="fissure-embed-cell fissure-embed-era">
              <img
                src={getEraIcon(fissure.era)}
                alt={fissure.era}
                className="fissure-embed-era-icon"
                onError={(e) => (e.target.style.display = "none")}
              />
              {fissure.era}
            </div>
            <div className="fissure-embed-cell fissure-embed-mission">
              <span className="fissure-embed-mission-text">
                {fissure.node} - {fissure.planet} ({fissure.mission})
              </span>
              <span className="fissure-embed-mission-hover">
                {fissure.enemy} - {fissure.tileset}
                <br />
                {formatTime(fissure.activation)} - {formatTime(fissure.expiry)}
                <br />
                {formatDuration(fissure.duration)}
              </span>
            </div>
            <div className="fissure-embed-cell fissure-embed-expiry">
              <Countdown
                date={moment.tz(fissure.expiry, "UTC").local().toDate()}
                renderer={renderer}
              />
            </div>
          </div>
        ))}
      </div>
      <EraConfigModal />
      <MissionConfigModal />
    </div>
  );
};

export default FissureEmbed;
